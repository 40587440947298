import IMG_COL_1 from "../assets/images/contactus/3.jpeg"
import IMG_COL_2 from "../assets/images/contactus/2.jpeg"
import IMG_COL_3 from "../assets/images/contactus/1.png"


export const data = [
  {
    title: "Need general or more specialized information?",
    description:
      "Contact a capability expert to answer questions and provide more knowledge resources...",
    image: IMG_COL_1,
    email: "email@email.com"
  },
  {
    title: "Need advisory support for pursuing an opportunity?",
    description:
      "Request support from our innovation factories for subject matter expertise, demonstrations, reviews and more...",
    image: IMG_COL_2,
    dataTarget:"#opportunity",
    id:"opportunity",
    function: "handleOpportunity"
  },
  {
    title: "Need a team to support project execution and delivery?",
    description:
      "Request support from our innovation factory teams for project execution, surge support , and more...",
    image: IMG_COL_3,
    dataTarget:"#execution",
    id:"execution",
    function: "handleExecution"
  }
  
]
