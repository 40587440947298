import React from 'react'
import Vee from '../assets/images/Vee.png'


export default function ApproachAI() {

  return (
    <div className="approachAIContainer" >

      <img src={Vee} style={{width: '100%'}} />
        
    </div>
  )

}