
import CHRIS_PROFILE from "../assets/images/profile/User_Avatar.png"
import MATT_PROFILE from "../assets/images/profile/MattPeterson.png"
import ELISSA_PROFILE from "../assets/images/profile/Elissa.png"


export const data = [
    {
        name: "CHRIS FINLAY",
        title: "VP of Innovation, Digital Systems Engineering and Ecosystem",
        email: "Christopher.g.finlay@saic.com",
        image: CHRIS_PROFILE
    },
    {
        name: "MATT PETERSON",
        title: "VP of Innovation, Digital Design, Development and Deployment",
        email: "Matthew.l.peterson@saic.com",
        image: MATT_PROFILE
    },
    {
        name: "ELISSA SHORE-ODELL",
        title: "Solutions Delivery Manager and Business Operations Lead",
        email: "Elissa.shore-Odell@saic.com",
        image: ELISSA_PROFILE
    }


]