import React from 'react'
import { 
    Typography,
    Grid,
    ButtonGroup,
    Button
 } from '@material-ui/core'
 import {
    RegCard
 } from '../components/cards'
 
import HOME_BANNER from '../assets/images/DE_HOME_BANNER.jpg'

import AboutAIJson from '../data/aboutAI.json'


export default function AboutAI() {

    const [state] = React.useState({
        dataValues: AboutAIJson
      })

  return (
    <div className="aboutAIContainer" >
     
        <Grid container xs={12}  className="banner" >

           <Grid item xs={12} sm={12}   >
     
                <Typography className="aboutTitle fadePanel" >
                <div>
                 <b><i className="line" >Engineering</i></b> the Right Way<br/>
                 <p className="aboutSubTitle" >The Engineering Innovation Factory transforms how an enterprise executes. It enables decision making with the use, management, and curation of data and models throughout the entire system life cycle</p>
                 </div>
                 </Typography>
               
                <img src={HOME_BANNER} alt="Home Banner"  />
            </Grid>
        
        </Grid>

        <div className="spacer" />

        <Grid container xs={12} sm={12} md={12} lg={12} className="homeButtons"   >
        <Grid item xs={12}  sm={12} md={12} lg={12} className="marginCenter" >

            <ButtonGroup variant="contained"  >
              <Button 
              style={{width: '100%'}}
              variant="contained"
              target="_blank"
              href="https://saiconline.sharepoint.us/:b:/r/teams/EngineeringInnovationFactoryRepository/Shared Documents/General/101 (Business Documents AKA Marketing_BD)/Offering Guide and Customer Brief/Digital Engineering Offering Guide.pdf?csf=1&web=1&e=neoTdA"
              >
                Offering Guide 
                </Button>
            
              <Button
                variant="contained"
                target="_blank"
                style={{width: '100%'}}
                href="https://saiconline.sharepoint.us/:p:/r/teams/EngineeringInnovationFactoryRepository/Shared Documents/General/101 (Business Documents AKA Marketing_BD)/Offering Guide and Customer Brief/Digital Engineering Customer Brief.pptx?d=w9c62e4ef41a54dd5b7d152dd8bf2b18a&csf=1&web=1&e=Fdk1dQ"
              >
                Customer Brief
              </Button>

              <Button
                variant="contained"
                target="_blank"
                style={{width: '100%'}}
                href="https://issaic.saic.com/sites/digital-engineering/de-eco-whitelist"
              >
                DE Ecosystem Offering
              </Button>
            </ButtonGroup>
             
          </Grid>
      </Grid> 

        <div className="spacer" />
        <div className="spacer" />
   
  
            <Grid container xs={12} sm={12} md={10} lg={12} columnSpacing={{ xs: 1, sm: 2, md: 3, lg:3 }} className="marginCenter"   >
            
                   {state.dataValues.map(item => { 
                  return <Grid item xs={12} sm={6} md={6} lg={4}  >
                 
                           <RegCard 
                            titleColor={item.color}
                            title={item.title} 
                            desc={item.description} 
                            />
    
                        </Grid>
                
                        })}
        
        </Grid>

        <div className="spacer" />
        <div className="spacer" />

        </div>    

  )

}
