import React from 'react'
import {
  Badge,

} from '@material-ui/core'
import {
    VIEW_ICON
  } from '../assets/icons/IconList'


const defaultProps = {
  children: <VIEW_ICON  />,
}

export default function BadgeComponent(props) {

  return (
    <div className="badgeComponentContainer" >
      &nbsp;Views&nbsp;<Badge badgeContent={props.count} {...defaultProps} color="primary" />&nbsp; 
    </div>
  )
}
