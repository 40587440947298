import { StylesProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppTemplate from './template/appTemplate'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from './Home'
import FAQPage from './faqs'
import DemosPage from './demos'
import OfferingsPage from './offerings'
import AboutUsPage from './aboutUs'
import PartnerRelations from './content/partnerRelations'
import ContactUsPage from './contactUs'

import './assets/sheets/main.scss'

function App() {

  return (

    <StylesProvider injectFirst >

    <CssBaseline />
      <AppTemplate>
      <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/faqs' element={<FAQPage />} />
              <Route path='/demos/:id'  element={<DemosPage />}  />
              <Route path='/offerings'  element={<OfferingsPage />}  />
              <Route path='/aboutUs' element={<AboutUsPage />} />
              <Route path='/partnerRelations' element={<PartnerRelations />} /> 
              <Route path='/contactUs' element={<ContactUsPage />} />
            </Routes>
        </BrowserRouter>  
      </AppTemplate>
    </StylesProvider>

  )
}

export default App
