import React from "react"
import { Grid } from "@material-ui/core"
import SAIC_LOGO from '../assets/images/Saic-Logo-Blue.png'


export default function Transition(props) {
  return (
    <Grid
        xs={12}
        container
        spacing={3}
        style={{height: "100vh", textAlign: "center", margin: '0 auto' }}
      >
      
        <Grid xs={8} item  style={{ margin: '50px auto' }} >
          <div className="fadePanel" >
            <img src={SAIC_LOGO} width="200" />
            <div className="spacer" />
            <div className="dividerShape" />
          </div>
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
            <div className="spacer" />
          <div class="loader" />
          <span style={{ color: "#063e65", opacity: "0.6"}}>
            AUTHENTICATION REQUIRED
          </span>
       
        </Grid>
        
      </Grid>
  )
}
